import { percentage } from "../helpers";
import moment from "moment";

type DataPoint = { id: string; x: string; y: number }
export type ChartInputData = {id: string, timestamp: string, [key: string]: string | number}
export type ChartConfig = {
  label: string;
  description?: string;
  module_id: number;
  axis: {
    x: { label: string };
    y: { max: number | null };
    y1?: { max: number | null; label: string };
  };
  group: "ambient_climates" | "agronomics" | "mildew_risk" | "soil_climates";
  legend: boolean;
  metrics: {
    [key: string]: {
      type: "line" | "area" | "bar" | "scatter";
      hidden: boolean;
      property?: string;
      includeValues?: boolean;
      formatter?: (value: Record<string, string | number>, field: {ec_division_factor: string}) => string | number;
      aggregation?: (data: ChartInputData[]) => DataPoint[];
    };
  };
  table: string[]
}

const toNumber = (value: string | number) => {
  return typeof value === "string" ? parseFloat(value) : value;
}

export default {
  ambient_climate: {
    label: "Ambient Climate",
    module_id: 3,
    axis: {
      x: { label: "" },
      y: { max: null },
    },
    group: "ambient_climates",
    legend: true,
    metrics: {
      ambient_temp: { type: "line", hidden: false, property: "temperature" },
      humidity: {
        type: "line",
        hidden: false,
        formatter: (value) => percentage(value.humidity, 1, 0),
      },
    },
    table: ["ambient_temp", "humidity"],
  },
  agronomics_overview: {
    label: "Agronomics Overview",
    module_id: 2,
    axis: {
      x: { label: "" },
      y: { max: null },
    },
    group: "agronomics",
    legend: true,
    metrics: {
      ag_ph_in: { type: "line", hidden: false, property: "ph_in" },
      ag_ph_out: { type: "line", hidden: false, property: "ph_out" },
      ag_ph_bag: { type: "line", hidden: false, property: "ph_bag" },
      ag_ec_in: { type: "line", hidden: false, property: "ec_in" },
      ag_ec_out: { type: "line", hidden: false, property: "ec_out" },
      ag_ec_sum: { type: "line", hidden: false, property: "ec_sum" },
      ag_ec_bag: { type: "line", hidden: false, property: "ec_bag" },
      ag_run_off: { type: "area", hidden: false, property: "run_off" },
      ag_moisture: { type: "line", hidden: false, property: "moisture" },
    },
    table: [
      "ag_ph_in",
      "ag_ph_out",
      "ag_ph_bag",
      "ag_ec_in",
      "ag_ec_out",
      "ag_ec_sum",
      "ag_ec_bag",
      "ag_ml_in",
      "ag_ml_out",
      "ag_run_off",
      "ag_moisture",
      "ag_water_cubes",
      "ag_irrigations_per_day",
      "ag_total_run_time",
      "ag_litres_per_day",
      "ag_drain_target",
      "ag_ec_target",
    ],
  },
  agronomics_ph: {
    label: "Agronomics - pH",
    module_id: 2,
    axis: {
      x: { label: "pH" },
      y: { max: 9 },
    },
    group: "agronomics",
    legend: true,
    metrics: {
      ag_ph_in: { type: "area", hidden: false, property: "ph_in" },
      ag_ph_out: { type: "area", hidden: false, property: "ph_out" },
      ag_ph_bag: { type: "line", hidden: false, property: "ph_bag" },
    },
    table: ["ag_ph_in", "ag_ph_out", "ag_ph_bag"],
  },
  agronomics_ec: {
    label: "Agronomics - EC",
    module_id: 2,
    axis: {
      x: { label: "EC" },
      y: { max: 5 },
    },
    group: "agronomics",
    legend: true,
    metrics: {
      ag_ec_in: { type: "area", hidden: false, property: "ec_in" },
      ag_ec_out: { type: "area", hidden: false, property: "ec_out" },
      ag_ec_sum: { type: "line", hidden: false, property: "ec_sum" },
      ag_ec_bag: { type: "line", hidden: false, property: "ec_bag" },
    },
    table: ["ag_ec_in", "ag_ec_out", "ag_ec_sum", "ag_ec_bag"],
  },
  vpd: {
    label: "VPD",
    module_id: 3,
    axis: {
      x: { label: "hPa" },
      y: { max: null },
    },
    group: "ambient_climates",
    legend: false,
    metrics: {
      vpd: { type: "line", hidden: false },
    },
    table: ["vpd"],
  },
  mildew: {
    description:
      "This is a decision support system designed to support the grower to have confidence to spray only when conditions are favourable for disease development. The Decision Support System (DSS) is based on a rule-based prediction system which measures the accumulated number of hours of disease conducive conditions where the temperatures are >15.5 C and <30C with a relative humidity of >60%. The Y-axis of the graph indicates the number of accumulated hours where both parameters are met, with the X-axis showing the date. When the graph reaches amber (115 hours) the grower should prepare to spray, such that they spray as the graph reaches red (125 hours). Once a fungicide has been applied, the system should be reset. When the line on the graph rises steeply there is a high risk indicating more frequent fungicide applications as the accumulated hours increase quickly, if the line on the graph is flatter there is a lower risk. The grower should be guided in the modes of action they use by the factsheet 29/16: 'Control of strawberry powdery mildew under protection'.",
    label: "Mildew Risk",
    module_id: 1,
    axis: {
      x: { label: "" },
      y: { max: null },
    },
    group: "mildew_risk",
    legend: false,
    metrics: {
      mildew: { type: "line", hidden: false, property: "value" },
    },
    table: ["mildew"],
  },
  growing_hours: {
    label: "Growing Degree Hours",
    module_id: 3,
    axis: {
      x: { label: "Hours" },
      y: { max: null },
    },
    group: "ambient_climates",
    legend: false,
    metrics: {
      growing_hours: {
        type: "area",
        hidden: false,
        aggregation: (data) => {
          const min = 4.5;
          const max = 32;
          const hours: DataPoint[] = [];

          data
            .filter((a) => {
              const minutes = moment(a.timestamp).minutes();
              return minutes >= 0 && minutes < 15;
            })
            .reduce((acc, val) => {
              const temp = toNumber(val.temperature);
              const calc =
                temp > min && temp < max
                  ? parseFloat((temp - min + acc).toFixed(2))
                  : parseFloat(acc.toFixed(2));
              hours.push({ id: val.id , x: val.timestamp , y: calc });
              return calc;
            }, 0);

          return hours;
        },
      },
    },
    table: ["growing_hours"],
  },
  chilling_hours: {
    label: "Chilling Hours",
    module_id: 3,
    axis: {
      x: { label: "Hours" },
      y: { max: null },
    },
    group: "ambient_climates",
    legend: false,
    metrics: {
      chilling_hours: {
        type: "area",
        hidden: false,
        aggregation: (data) => {
          const hours: DataPoint[] = [];

          data
            .filter((a) => {
              const minutes = moment(a.timestamp).minutes();
              return minutes >= 0 && minutes < 15;
            })
            .reduce((acc, val) => {
              const temp = toNumber(val.temperature);
              const calc =
                temp < 7
                  ? parseFloat((acc + 1).toFixed(2))
                  : parseFloat(acc.toFixed(2));
              hours.push({ id: val.id , x: val.timestamp , y: calc });
              return calc;
            }, 0);
          return hours;
        },
      },
    },
    table: ["chilling_hours"],
  },
  soil_climate: {
    label: "Root-Zone Moisture Content",
    module_id: 4,
    axis: {
      x: { label: "" },
      y: { max: null },
    },
    group: "soil_climates",
    legend: true,
    metrics: {
      moisture_content: { type: "line", hidden: false },
      squeeze: { type: "scatter", hidden: false },
    },
    table: ["moisture_content"],
  },
  soil_moisture: {
    label: "Moisture Content",
    module_id: 4,
    axis: {
      x: { label: "" },
      y: { max: null },
    },
    group: "soil_climates",
    legend: true,
    metrics: {
      moisture_10: { type: "line", hidden: false },
      moisture_20: { type: "line", hidden: false },
      moisture_30: { type: "line", hidden: false },
      moisture_40: { type: "line", hidden: false },
      moisture_50: { type: "line", hidden: false },
      moisture_60: { type: "line", hidden: false },
      moisture_70: { type: "line", hidden: false },
      moisture_80: { type: "line", hidden: false },
      moisture_90: { type: "line", hidden: false },
    },
    table: [
      "moisture_10",
      "moisture_20",
      "moisture_30",
      "moisture_40",
      "moisture_50",
      "moisture_60",
      "moisture_70",
      "moisture_80",
      "moisture_90",
    ],
  },
  soil_moisture_gains: {
    label: "Crop Water Usage & Gains",
    module_id: 4,
    axis: {
      x: { label: "mm" },
      y: { max: null },
    },
    group: "soil_climates",
    legend: true,
    metrics: {
      //    gains: { type: "bar", hidden: false }, // must remain for chart rendering
      gains_pos: { type: "bar", hidden: false },
      gains_neg: { type: "bar", hidden: false },
      // gains_daily: { type: "line", hidden: false },
    },
    table: ["gains"],
  },
  vic: {
    label: "Conductivity",
    module_id: 4,
    axis: {
      x: { label: "" },
      y: { max: null },
    },
    group: "soil_climates",
    legend: true,
    metrics: {
      vic_10: { type: "line", hidden: false },
      vic_20: { type: "line", hidden: false },
      vic_30: { type: "line", hidden: false },
      vic_40: { type: "line", hidden: false },
      vic_50: { type: "line", hidden: false },
      vic_60: { type: "line", hidden: false },
      vic_70: { type: "line", hidden: false },
      vic_80: { type: "line", hidden: false },
      vic_90: { type: "line", hidden: false },
    },
    table: [
      "vic_10",
      "vic_20",
      "vic_30",
      "vic_40",
      "vic_50",
      "vic_60",
      "vic_70",
      "vic_80",
      "vic_90",
    ],
  },
  soil_temp: {
    label: "Temperature",
    module_id: 4,
    axis: {
      x: { label: "" },
      y: { max: null },
    },
    group: "soil_climates",
    legend: true,
    metrics: {
      soil_temp_10: { type: "line", hidden: false },
      soil_temp_20: { type: "line", hidden: false },
      soil_temp_30: { type: "line", hidden: false },
      soil_temp_40: { type: "line", hidden: false },
      soil_temp_50: { type: "line", hidden: false },
      soil_temp_60: { type: "line", hidden: false },
      soil_temp_70: { type: "line", hidden: false },
      soil_temp_80: { type: "line", hidden: false },
      soil_temp_90: { type: "line", hidden: false },
    },
    table: [
      "soil_temp_10",
      "soil_temp_20",
      "soil_temp_30",
      "soil_temp_40",
      "soil_temp_50",
      "soil_temp_60",
      "soil_temp_70",
      "soil_temp_80",
      "soil_temp_90",
    ],
  },
  rainfall: {
    label: "Rainfall",
    module_id: 4,
    axis: {
      x: { label: "" },
      y: { max: null },
      y1: { max: null, label: "Water (cubes)" },
    },
    group: "soil_climates",
    legend: true,
    metrics: {
      rainfall: { type: "bar", hidden: false },
      water_cubes: { type: "bar", hidden: false },
    },
    table: ["rainfall"],
  },
  run_off: {
    label: "Run-Off",
    module_id: 4,
    axis: {
      x: { label: "" },
      y: { max: null },
    },
    group: "soil_climates",
    legend: true,
    metrics: {
      run_off_in: { type: "bar", hidden: false },
      run_off_out: { type: "bar", hidden: false },
      run_off: { type: "area", hidden: false, includeValues: true },
    },
    table: ["run_off_in", "run_off_out", "run_off"],
  },
  ph: {
    label: "pH",
    module_id: 4,
    axis: {
      x: { label: "pH" },
      y: { max: null },
    },
    group: "soil_climates",
    legend: true,
    metrics: {
      ph_in: { type: "area", hidden: false },
      ph_out: { type: "area", hidden: false },
      ph_bag: { type: "line", hidden: false },
    },
    table: ["ph_in", "ph_out", "ph_bag"],
  },
  ec: {
    label: "EC",
    module_id: 4,
    axis: {
      x: { label: "EC" },
      y: { max: null },
    },
    group: "soil_climates",
    legend: true,
    metrics: {
      ec_in: {
        type: "area",
        hidden: false,
        formatter: (value, field) => {
          var factor = field?.ec_division_factor
            ? parseFloat(field.ec_division_factor)
            : 10000;
          return toNumber(value.ec_in) / factor;
        },
      },
      ec_out: {
        type: "area",
        hidden: false,
        formatter: (value, field) => {
          var factor = field?.ec_division_factor
            ? parseFloat(field.ec_division_factor)
            : 10000;
          return toNumber(value.ec_out) / factor;
        },
      },
      ec_sum: {
        type: "line",
        hidden: false,
        formatter: (value, field) => {
          var factor = field?.ec_division_factor
            ? parseFloat(field.ec_division_factor)
            : 10000;
          return toNumber(value.ec_sum) / factor;
        },
      },
      ec_bag: {
        type: "line",
        hidden: false,
        formatter: (value, field) => {
          var factor = field?.ec_division_factor
            ? parseFloat(field.ec_division_factor)
            : 10000;
          return toNumber(value.ec_bag) / factor;
        },
      },
    },
    table: ["ec_in", "ec_out", "ec_sum", "ec_bag"],
  },
} satisfies Record<string, ChartConfig>;
